<template>
  <div>
    <div class="row">
      <div class="col-12 text-white">快捷鍵功能：<span class="text-primary">F8</span> 銷售人員，<span class="text-primary">F9</span> 商品編碼。<span class="text-primary">「+」</span> 鍵 + <span class="text-primary">「Enter」</span> 鍵 快速到達結帳按鈕</div>
    </div>
    <div class="row">
      <div class="col-7">
        <div class="card">
          <div class="card-header">
            <div class="card-subtotal container">
              <div class="row">
                <div class="col-9">
                  <div class="card-sec-title">銷售總計金額</div>
                  <h1 class="card-title">{{formatLocalString(computedTotalAmount)}}</h1> 
                </div>
                <div class="col-3">
                  <el-select class="select-primary text-right" size="large" placeholder="請選擇銷售人員" v-model="salesName" @change="handleSelect">
                  <el-option v-for="option in mappingStaff" class="select-primary" :value="option.mappingName" :label="option.mappingName" :key="option.staffID"></el-option>
                </el-select>
                </div>
              </div>
            </div> 
            <hr>
            <div class="card-footer">
              <div class="row">
              <div class="col-lg-6 align-middle">共 {{computedProduct.number}} 項商品，合計 {{computedProduct.total}} 個。</div>
            </div>
            </div>
          </div>
        </div>
        <!-- 結帳購物籃 -->
        <card class="pos-car">
          <div class="table-responsive">
            <el-table :data="salesTable" empty-text="尚未有結帳紀錄" header-cell-class-name="" row-class-name="" v-loading="isLoading">
              <el-table-column label="商品編號" property="SKU" width="100"></el-table-column>
              <el-table-column label="商品名稱" property="name" min-width="180"></el-table-column>
              <el-table-column label="售價" align="right" property="defaultPrice" header-align="right">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">
                    {{ formatLocalString(scope.row.defaultPrice)}}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="數量" property="count" align="right" header-align="center">
                <template slot-scope="{row}">
                  <input type="number" min="1" @change.prevent="handleCount(row)" v-model.number="row.count" class="form-control text-center"/>
                </template>
              </el-table-column>
              <el-table-column label="小計" property="totalAmount" align="right" header-align="right" >
                <template slot-scope="scope">
                  <span style="margin-left: 10px">
                    {{ formatLocalString(scope.row.totalAmount) }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="銷售人員" property="salesName" align="left" header-align="center" ></el-table-column>
              <el-table-column align="right" header-align="right" min-width="50" >
                <template slot-scope="{row}">
                  <n-button @click.native="handleDelete(row)" class="remove" type="outline-primary" size="sm" round icon>
                    <i class="now-ui-icons ui-1_simple-remove"></i>
                  </n-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </card>
        
        <div class="row">
          <div class="col-6">
            <card >
              <template slot="header">
                <h4 class="card-title">回收埋線券</h4>
              </template>
              <div class="row px-2">
                <div class="d-flex">
                  <div class="input-group mb-3 mt-1 pr-3">
                    <input type="text" class="form-control"  placeholder="起號 (完整十碼)" aria-label="giftStart" name="giftStart" v-model="giftStart" maxlength="10">
                    <span class="input-group-text ticket-mid-dots">⋯⋯</span>
                    <input type="text" class="form-control" placeholder="訖號 (末三碼)" aria-label="giftEnd" name="giftEnd" v-model="giftEnd" maxlength="3">
                  </div>
                  <div>
                    <n-button type="primary" round icon size="sm" @click.native="addGiftData" :disabled="!giftStart">
                      <i class="now-ui-icons ui-1_simple-add"></i>
                    </n-button>
                  </div>
                </div>
                <div>
                  <span class="text-danger m-1" v-if="errorMsg.error && errorMsg.type === 'gift'">{{errorMsg.msg}}</span>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <span class="font-12" style="color: #909399;">回收埋線券詳細資料（共 {{renderVoucherData('gift').total}} 張）</span>
                <n-button type="outline-primary" class="font-12 border-0 m-0 p-0" @click.native="removeVoucher('gift')">清除埋線券</n-button>
              </div>
              <el-table :data="renderVoucherData('gift').data" row-class-name="font-16" class="fixedH200" :show-header="false">
                <el-table-column
                  min-width="150"
                  property="voucherNumber"
                ></el-table-column>
                <el-table-column
                  align="right"
                  header-align="right"
                  min-width="50"
                >
                <template slot-scope="props">
                  <n-button @click.native="handleVoucher(props.row)" class="remove" type="danger" size="sm" round icon>
                    <i class="fa fa-times"></i>
                  </n-button>
                </template>
                </el-table-column>
              </el-table>
            </card>
          </div>
          <div class="col-6">
            <card>
              <template slot="header">
                <h4 class="card-title">回收免掛券</h4>
              </template>
              <div class="row px-2">
                <div class="d-flex">
                  <div class="input-group mb-3 mt-1 pr-3">
                    <input type="text" class="form-control"  placeholder="起號 (完整八碼)" aria-label="couponStart" name="couponStart" v-model="couponStart" maxlength="8">
                    <span class="input-group-text ticket-mid-dots">⋯⋯</span>
                    <input type="text" class="form-control" placeholder="訖號 (末三碼)" aria-label="couponEnd" name="couponEnd" v-model="couponEnd" maxlength="3">
                  </div>
                  <div>
                    <n-button type="primary" round icon size="sm" @click.native="addCouponData" :disabled="!couponStart">
                      <i class="now-ui-icons ui-1_simple-add"></i>
                    </n-button>
                  </div>
                </div>
                <div>
                  <span class="text-danger m-1" v-if="errorMsg.error && errorMsg.type === 'coupon'">{{errorMsg.msg}}</span>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <span class="font-12" style="color: #909399;">免掛券詳細資料（共 {{renderVoucherData('coupon').total}} 張）</span>
                <n-button type="outline-primary" class="font-12 border-0 m-0 p-0" @click.native="removeVoucher('coupon')">清除免掛券</n-button>
              </div>
              <el-table :data="renderVoucherData('coupon').data" row-class-name="font-16" class="fixedH200" :show-header="false">
                <el-table-column
                  min-width="150"
                  property="voucherNumber"
                ></el-table-column>
                <el-table-column
                  align="right"
                  header-align="right"
                  min-width="50"
                >
                  <template slot-scope="props">
                    <n-button @click.native="handleVoucher(props.row)" class="remove" type="danger" size="sm" round icon>
                      <i class="fa fa-times"></i>
                    </n-button>
                  </template>
                </el-table-column>
              </el-table>
            </card>
          </div>
        </div>
        <card>
          <div class="text-right">
            <button type="button" id="payment" class="btn btn-primary btn-lg btn-round btn-block" @click.prevent="goToPayment" :disabled="computedDisabledPayment">{{isNew ? '前往結帳' : '確認修改訂單'}}</button>
          </div>
        </card>
      </div>

      <div class="col-5">
        <card>
          <tabs type="primary" v-model="tabs.index">
            <tab-pane label="常用商品">
              <el-table :data="computedFavoProduct" @cell-click="handleProduct" style="cursor: pointer;" class="user-select-none my-2">
                <el-table-column min-width="60" label="商品編號" property="SKU"></el-table-column>
                <el-table-column min-width="120" label="商品名稱" property="name"></el-table-column>
                <el-table-column min-width="60" align="right" header-align="right" label="參考原售價" property="defaultSellingPrice">
                  <template slot-scope="scope">
                    {{ formatLocalString(scope.row.defaultSellingPrice) }}
                  </template>
                </el-table-column>
                <el-table-column min-width="60" align="right" header-align="right" label="會員售價" property="defaultPrice">
                  <template slot-scope="scope">
                    {{ formatLocalString(scope.row.defaultPrice) }}
                  </template>
                </el-table-column>
              </el-table>
            </tab-pane>
            <tab-pane label="所有商品">
              <el-table :data="products" @cell-click="handleProduct" style="cursor: pointer;" class="user-select-none my-2">
                <el-table-column min-width="60" label="商品編號" property="SKU"></el-table-column>
                <el-table-column min-width="120" label="商品名稱" property="name"></el-table-column>
                <el-table-column min-width="60" align="right" header-align="right" label="參考原售價" property="defaultSellingPrice">
                  <template slot-scope="scope">
                    {{ formatLocalString(scope.row.defaultSellingPrice) }}
                  </template>
                </el-table-column>
                <el-table-column min-width="60" align="right" header-align="right" label="售價" property="defaultPrice">
                  <template slot-scope="scope">
                    {{ formatLocalString(scope.row.defaultPrice) }}
                  </template>
                </el-table-column>
              </el-table>
            </tab-pane>
          </tabs>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, TabPane, Tabs } from 'src/components'
import { Table, TableColumn, Select, Option} from 'element-ui'
import { extend } from 'vee-validate'
import { required, min, max, min_value, max_value} from 'vee-validate/dist/rules'
import Swal from 'sweetalert2'
import API from 'src/api/index.js'
import moment from 'moment'
import _ from 'lodash'

extend('required', required)
extend('min', min)
extend('max', max)
extend('min_value', min_value)
extend('max_value', max_value)

export default {
  components: {
    TabPane,
    Tabs,
    Card,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      staff: [],
      salesName:  '',
      orderTable: [],
      salesTable: [],
      originOrder: [],
      member: {},
      products: [],
      voucherData: [],
      closeOrderStaff: '',
      tabs: {
        index: '常用商品'
      },
      errorMsg: {error: false, msg: '*起迄號碼有誤', type: ''},
      openTime: null,
      tradeID: null,
      giftStart: null,
      giftEnd: null,
      couponStart: null,
      couponEnd: null,
      count: 1,
      isCloseOrder: false,
      isMode: false,
      isLoading: false,
      isNew: true,
      quickPayment: []
    }
  },
  methods: {
    async init () {
      let product = []
      let productResult = await API.getProduct()
      if (productResult.message === 'success') {
        product = productResult.data
      }
      let closeResult = await API.checkCloseAccount()
      if (closeResult.message === 'success') {
        this.isCloseOrder = Object.keys(closeResult.data).length >= 1
      }
      let staffResult = await API.getStaff()
      if (staffResult.message === 'success') {
        this.staff = staffResult.data
      }
      // 若沒有開帳人員，則導入開帳頁面
      if (!this.isCloseOrder) {
        this.checkCloseAccount()
        return
      }
      let localStorageData = JSON.parse(localStorage.getItem('chengyoData'))
      let memberData = _.get(localStorageData, 'member', {})
      let salesNameData = _.get(localStorageData, 'salesName', {})
      let orderData = _.get(localStorageData, 'order', {})
      this.originOrder = _.get(localStorageData, 'order', {})
      // init 開帳人員
      if (localStorageData && Object.keys(memberData).length > 0) {
        // products 商品
        // orderTable 計算
        this.member = localStorageData.member
        if (Object.keys(this.member.type).length === 0 || this.member.type.value === 'normalPrice') {
          // 原價參考
          this.products = _.map(product, r => {
            r.defaultPrice = r.defaultSellingPrice
            r.defaultDiscoutPrice = !r.quantityDiscoutPrice ? 0 : r.quantityDiscoutPrice
            return r
          })
        } else {
          this.products =  _.map(product, r => {
            // 依據不同身份別
            let type = _.find(r.priceBook, {id : this.member.type.value})
            if (type) {
              // 參考價格若無設定則參照 defaultSellingPrice
              r.defaultPrice = !type.price ? r.defaultSellingPrice : type.price
              r.defaultDiscoutPrice = !type.discountPrice ? r.quantityDiscoutPrice : type.discountPrice
              r.rewardPrice = !type.rewardPrice ? r.rewardPrice : type.rewardPrice
            }
            return r
          })
        }
      }
      // init 業務人員
      if (localStorageData && Object.keys(salesNameData).length > 0) {
        this.salesName = salesNameData.mappingName
      }
      // TODO: 抽出
      // init 修改訂單
      if (!this.isNew && Object.keys(orderData).length > 0) {
        // this.orderTable
        this.voucherData = localStorageData.order.voucherEffectiveList
        this.openTime = localStorageData.order.openTime
        this.tradeID = localStorageData.order.tradeID
        let productArr = []
        _.chain(localStorageData.order.saleDetails)
          .forEach(r => {
            _.filter(this.products, o => {
              if (o.productUUID === r.productUUID) {
                productArr.push(o)
              }
            })
            for (let index = 0; index < r.qty; index++) {
              let data = _.find(productArr, {productUUID: r.productUUID})
              if (data) {
                data.salesName = r.salesName
                data.staffID = r.staffID
                data.staffName = r.staffName
                data.count = 1
                data.id = r.productUUID + r.salesName
                this.orderTable.push(data)
                this.renderSalesTable()
              }
            }
          })
          .value()
      }
    },
    addGiftData () {
      this.errorMsg.error = false
      if (this.giftStart.length > 10 || this.giftStart.length < 10) {
        this.errorMsg = {error: true, msg: '*起號碼有誤，起號須為 10 碼', type: 'gift'}
        return
      }
      let start = Number(this.giftStart.slice(7))
      if (this.giftEnd) {
        if (this.giftEnd.length !== 3) {
          this.errorMsg = {error: true, msg: '*迄號碼有誤，迄號須為 3 碼', type: 'gift'}
          return
        }
        this.voucherData.push({voucherNumber: this.giftStart.toLocaleUpperCase(), type: 'gift'})
        let calc = this.giftEnd - this.giftStart.slice(7)
        for (let index = 0; index < calc; index++) {
          start += 1
          if (start < 100) {
            // < 100 補 0
            let mappingStart = String(start).padStart(3, '0')
            this.voucherData.push({voucherNumber: this.giftStart.toLocaleUpperCase().slice(0, 7) + mappingStart, type: 'gift'})
          } else {
            let obj = {voucherNumber: this.giftStart.toLocaleUpperCase().slice(0, 7) + start, type: 'gift'}
            this.voucherData.push(obj)
          }
        }
      } else {
        this.voucherData.push({voucherNumber: this.giftStart.toLocaleUpperCase(), type: 'gift'})
      }
      this.giftStart = null
      this.giftEnd = null
    },
    addCouponData () {
      this.errorMsg.error = false
      if (this.couponStart.length > 8 || this.couponStart.length < 8) {
        this.errorMsg = {error: true, msg: '*起號碼有誤，起號須為 8 碼', type: 'coupon'}
        return
      }
      let start = Number(this.couponStart.slice(5))
      if (this.couponEnd) {
        if (this.couponEnd.length !== 3) {
          this.errorMsg = {error: true, msg: '*迄號碼有誤，迄號須為 3 碼', type: 'coupon'}
          return
        }
        this.voucherData.push({voucherNumber: this.couponStart.toLocaleUpperCase(), type: 'coupon'})
        let calc = this.couponEnd - this.couponStart.slice(5)
        for (let index = 0; index < calc; index++) {
          start += 1
          if (start < 100) {
            // < 100 補 0
            let mappingStart = String(start).padStart(3, '0')
            this.voucherData.push({voucherNumber: this.couponStart.toLocaleUpperCase().slice(0, 5) + mappingStart, type: 'coupon'})
          } else {
            let obj = {voucherNumber: this.couponStart.toLocaleUpperCase().slice(0, 5) + start, type: 'coupon'}
            this.voucherData.push(obj)
          }
        }
      } else {
        this.voucherData.push({voucherNumber: this.couponStart.toLocaleUpperCase(), type: 'coupon'})
      }
      this.couponStart = null
      this.couponEnd = null
    },
    removeVoucher (type) {
      let voucherData = _.cloneDeep(this.voucherData)
      if (type === 'gift') {
        this.voucherData = voucherData.filter(o => o.type === 'coupon')
      } else if (type === 'coupon') {
        this.voucherData = voucherData.filter(o => o.type === 'gift')
      }
    },
    checkCloseAccount () {
      Swal.fire({
        title: '尚無開帳人員，確認後，前往開帳頁面',
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-info btn-fill',
        preConfirm: async () => {
          this.$router.push('/order')
        }
      })
    },
    goToPayment () {
      if (!this.isNew && this.salesTable.length === 0) {
        Swal.fire({
          title: '確認作廢全單？',
          html: `<div class="form-group">
                <div class="d-flex justify-content-start swal2-validation-message">
                  <div>應退現金金額： ${this.formatLocalString(this.originOrder.totalSaleAmount)}</div>
                </div>
              </div>`,
          buttonsStyling: false,
          confirmButtonText: '<i class="fa fa-save"></i> 確認作廢',
          confirmButtonClass: 'btn btn-primary btn-lg btn-round btn-block',
          preConfirm: async () => {
            let {orderID, memberID, memberName, memberType, payment, invoiceType, invoiceNumber, taxID, taxType, taxRate, taxAmount, saleDetails, totalSaleAmount } = this.originOrder
            let data = {
              returnOrderID: orderID,
              tradeDate: moment().format('X'),
              memberID,
              memberName,
              memberType,
              payment,
              invoiceType,
              invoiceDate: moment().format('X'),
              invoiceNumber,
              taxID,
              taxType,
              taxRate,
              taxAmount,
              totalSaleAmount: -totalSaleAmount,
              voucherEffectiveList: [],
              // 作廢流程須更新總額(totalSaleAmount)，產品details下的數量(qty)、獎金(rewardPrice)調整為負數
              saleDetails: saleDetails.map(r => {
                let {SKU, barcode, category, name, productUUID, qty, quantityDiscout, quantityDiscoutPrice, rewardPrice, salePrice, staffID, staffName} = r
                return {SKU, barcode, category, name, productUUID, qty: -(qty), quantityDiscout, quantityDiscoutPrice, rewardPrice: -(rewardPrice), salePrice, staffID, staffName}
              })
            }
            let reuslt = await API.returnSalesorder(JSON.stringify(data))
            if (reuslt.message === 'success') {
              Swal.close()
              this.$router.push('/order')
            }
          }
        })
        return
      }
      Swal.fire({
        title: this.isNew ? '確認結帳' : '確認修改訂單',
        html: `<div class="form-group">
                <div class="d-flex justify-content-start swal2-validation-message">
                  <div>應收現金金額： ${this.formatLocalString(this.computedTotalAmount)}</div>
                </div>
              </div>`,
        inputValue: 'cash',
        inputPlaceholder: '請選擇付款方式',
        showCancelButton: true,
        confirmButtonText: '確認付款',
        cancelButtonText: '取消',
        allowEnterKey: false,
        preConfirm: async () => {
          let saleDetails = _.map(_.cloneDeep(this.salesTable), r => {
            r.qty = r.count
            r.quantityDiscoutPrice = r.defaultDiscoutPrice
            r.salePrice = r.defaultPrice
            r.rewardPrice = Math.round(r.rewardPrice * r.qty)
            delete r.defaultDiscoutPrice
            delete r.count
            delete r.id
            delete r.salesName
            delete r.totalAmount
            return r
          })
          let data = {
            tradeDate: moment().format('X'),
            memberID: this.member.memberID,
            memberName: this.member.name,
            memberType: !_.get(this.member, 'type.value', '') ? '' : this.member.type.value,
            payment: 'cash',
            invoiceType: 0,
            invoiceDate: moment().format('X'),
            invoiceNumber: '',
            taxID: '',
            taxType: 0,
            taxRate: 0.05,
            taxAmount: 0,
            totalSaleAmount: this.computedTotalAmount,
            saleDetails: saleDetails,
            voucherEffectiveList: this.voucherData
          }
          if (!this.isNew) {
            data.orderID = this.$router.history.current.params.id
            data.tradeID = this.tradeID
            data.openTime = this.openTime
          }
          let apiType = this.isNew ? API.createSalesorder(JSON.stringify(data)) : API.modifySalesorder(JSON.stringify(data),  data.orderID)
          let reuslt = await apiType
          if (reuslt.message === 'success') {
            Swal.fire({
              title: '建立訂單成功！',
              icon: 'success',
              html: `<div class="form-group">
                      <div class="d-flex justify-content-start swal2-validation-message">
                        <div class="text-left">
                          <div>訂單金額：${this.formatLocalString(this.computedTotalAmount)}</div>
                          <div>訂單號碼：<span class="text-primary font-weight-bolder">${reuslt.data.tradeID}</span> 已成功建立</div>
                        </div>
                      </div>
                    </div>`,
              
            }).then((result) => {
              if (result.isConfirmed) {
                Swal.close()
                this.$router.push('/member')
              }
            })
          }
        }
      })
    },
    async showShortcut (type) {
      switch (type) {
        case 'F3':
        case 'F9':
          this.isMode = 'product'
          Swal.fire({
            title: '商品快捷輸入',
            html: `<div class="form-group">
                    <div class="d-flex justify-content-between">
                      <input id="qty" type="number" min="1" class="swal2-input input-text" placeholder="數量(QTY)"/>
                      <span class="align-self-center"> X </span>
                      <input id="SKU" type="number" min="0" class="swal2-input input-text" placeholder="品名(SKU)"/>
                    </div>
                  </div>`,
            buttonsStyling: false,
            confirmButtonText: '確認送出',
            confirmButtonClass: 'btn btn-outline-primary btn-lg btn-round btn-block',
            focusConfirm: false,
            preConfirm: () => {
              this.shortcutType(this.isMode)
            }
          })
          break
        case 'F2':
        case 'F8':
          this.isMode = 'staff'
          Swal.fire({
            title: '快捷輸入銷售人員',
            html: `<div class="text-center">
                    <input id="staff-recordID" type="text" min="0" class="swal2-input input-text text-center" placeholder="請輸入銷售人員代碼四碼/醫師代碼三碼"/>
                  </div>`,
            buttonsStyling: false,
            focusConfirm: false,
            confirmButtonText: '確認送出',
            confirmButtonClass: 'btn btn-outline-primary btn-lg btn-round btn-block',
            preConfirm: async () => {
              this.shortcutType(this.isMode)
            }
          })
          break
        case 'NumpadMultiply':
        case 'KeyX':
          if (Swal.isVisible()) {
            document.getElementById('qty').blur()
            document.getElementById('SKU').focus()
          }
          break
        case 'NumpadAdd':
        case 'Equal':
          // - 移動到結帳
          this.quickPayment.push(type)
          document.querySelector('#payment').scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})
          break
        case 'Enter':
        case 'NumpadEnter':
          // 在swal開啟狀態，才需支援快捷鍵 Enter、X、*
          if (Swal.isVisible()) {
            this.shortcutType(this.isMode)
          } else if (!Swal.isVisible() && this.$route.path === '/pos') {
            if (this.quickPayment[0] === 'NumpadAdd' || this.quickPayment[0] === 'Equal') {
              let event = new MouseEvent('click')
              let paymentButton = document.getElementById('payment')
              paymentButton.dispatchEvent(event)
            }
          }
          this.quickPayment = []
          break
      }
    },
    shortcutType (type) {
      switch (type) {
        case 'product':
          {
            let qty = document.getElementById('qty').value
            let sku = document.getElementById('SKU').value
            if (!qty || !sku) return
            let row = _.find(this.products, {SKU: sku})
            if (row) {
              this.calculateProduct({count: Number(qty), totalAmount: 0, row: row})
            } else {
              Swal.fire({
                title: '查無此商品',
                html: `資料庫中搜尋不到 SKU:<span class="text-primary">${sku}</span> 的商品資料`,
                buttonsStyling: false,
                confirmButtonText: '返回',
                confirmButtonClass: 'btn btn-outline-primary btn-lg btn-round btn-block'
              })
            }
          }
          break
        case 'staff':
          {
            let staffID = document.getElementById('staff-recordID').value.toUpperCase()
            let member = _.find(this.mappingStaff, {staffID: staffID})
            if (member) {
              let name = member.name
              let mappingName = member.mappingName
              let localStorageData = JSON.parse(localStorage.getItem('chengyoData'))
              if (localStorageData) {
                localStorage.setItem('chengyoData', JSON.stringify(Object.assign(localStorageData, {salesName: {mappingName, staffID, staffName: name}})))
                this.init()
              }
              Swal.close()
            } else {
              Swal.fire({
                title: '查無此員工編號',
                html: `資料庫中搜尋不到 員工編號:<span class="text-primary">${staffID}</span> 的資料`,
                buttonsStyling: false,
                confirmButtonText: '返回',
                confirmButtonClass: 'btn btn-outline-primary btn-lg btn-round btn-block'
              })
            }
          }
          break
      }
      
    },
    calculateProduct ({count = 0, totalAmount = 0, row}) {
      // init product資料
      let localStorageData = JSON.parse(localStorage.getItem('chengyoData'))
      if (!_.get(localStorageData, 'salesName', '')) {
        Swal.fire({
          title: '請選擇銷售人員',
          type: 'warning'
        })
        return
      }
      if (localStorageData && localStorageData.salesName) {
        row.salesName = localStorageData.salesName.mappingName
        row.staffName = localStorageData.salesName.staffName
        row.staffID = localStorageData.salesName.staffID
      }
      // id = productUUID + salesName 來 mapping 業績
      // id = d7b260f1-5deb-4791-8cc1-d6240b6a00c0(0003) 魏言卉
      row.id = row.productUUID + row.salesName
      row.count = count
      row.totalAmount = totalAmount
      // call by refences，需要cloneDeep
      this.orderTable.push(_.cloneDeep(row))
      this.renderSalesTable()
      Swal.close()
    },
    renderSalesTable () {
      this.isLoading = true
      this.salesTable = _.reduce(this.orderTable, (r, o) => {
        let {id} = o
        let product = _.find(r, {id})
        if (product) {
          product.count += o.count
          // product.rewardPrice += o.rewardPrice
          if (o.quantityDiscout > 1 && product.count >= o.quantityDiscout) {
            // 當quantityDiscout ≠ 1，參考 discountPrice，否則參考price (選擇數量 / quantityDiscout) * defaultDiscoutPrice + (選擇數量 % quantityDiscout) * defaultPrice
            product.totalAmount = Math.floor(product.count / o.quantityDiscout) * o.defaultDiscoutPrice + (product.count % o.quantityDiscout) * o.defaultPrice
          } else {
            product.totalAmount += o.count * o.defaultPrice
          }
        } else {
          let { name, SKU, barcode, category, defaultPrice, count, quantityDiscout, quantityDiscoutPrice, defaultDiscoutPrice, rewardPrice, staffID, staffName, salesName, productUUID } = o
          let mappingTotalAmount = 0
          if (o.quantityDiscout > 1 && count >= o.quantityDiscout) {
            mappingTotalAmount = Math.floor(count / o.quantityDiscout) * o.defaultDiscoutPrice + (count % o.quantityDiscout) * o.defaultPrice
          } else {
            mappingTotalAmount = count * defaultPrice
          }
          r.push({
            id,
            totalAmount: mappingTotalAmount,
            productUUID,
            count: count,
            SKU,
            barcode,
            category,
            defaultPrice,
            defaultDiscoutPrice,
            quantityDiscoutPrice,
            quantityDiscout,
            rewardPrice,
            staffID,
            staffName,
            salesName,
            name
          })
        }
        return r
      },[])
      this.isLoading = false
    },
    renderVoucherData (type) {
      let data = _.chain(this.voucherData)
        .cloneDeep()
        .filter({type: type})
        .uniqBy('voucherNumber')
        .sortBy(['voucherNumber'])
        .value()
      return {data, total: data.length}
    },
    handleVoucher (row) {
      let index = _.findIndex(this.voucherData, {type: row.type, voucherNumber: row.voucherNumber})
      if (index > -1) {
        this.voucherData.splice(index, 1)
      }
    },
    handleSelect () {
      let item = _.find(this.mappingStaff, {mappingName: this.salesName})
      if (item) {
        let {staffID, name, mappingName} = item
        let localStorageData = JSON.parse(localStorage.getItem('chengyoData'))
        if (localStorageData) {
          localStorage.setItem('chengyoData', JSON.stringify(Object.assign(localStorageData, {salesName: {mappingName, staffID, staffName: name}})))
        }
      }
    },
    handleProduct(row) {
      this.calculateProduct({count: 1, totalAmount: 0, row})
    },
    handleCount(row) {
      let count = Number(row.count)
      let data = _.filter(_.cloneDeep(this.orderTable), {id: row.id})
      let productCount = count - data.length
      // 參考 count 數量，進行 array 計算
      if (productCount >= 1) {
        for (let index = 0; index < productCount; index++) {
          row.count = 1
          this.orderTable.push(row)
          this.renderSalesTable()
        }
      } else {
        let index = Math.abs(productCount)
        for (let i = 0; i < index; i++) {
          let index = _.findIndex(this.orderTable, r => {
            if (r.id === row.id && r.productUUID === row.productUUID) {
              return r
            }
          })
          if (index > -1) {
            this.orderTable.splice(index, 1)
          }
          this.renderSalesTable()
        }
      }
    },
    handleDelete(row) {
      let filterData = _.filter(this.orderTable, {id: row.id})
      if (filterData.length >= 1) {
        filterData.forEach(r => this.orderTable.splice(this.orderTable.findIndex(o => o.id === r.id),1))
        this.renderSalesTable()
      }
    },
    formatLocalString (value) {
      return `$ ${value.toLocaleString()}`
    }
  },
  computed: {
    computedTotalAmount () {
      let sum = _.chain(this.salesTable)
        .cloneDeep()
        .map('totalAmount')
        .reduce((sum, price) => { return sum + price}, 0)
        .value()
      return sum
    },
    computedProduct () {
      let total = _.chain(this.salesTable)
        .cloneDeep()
        .map('count')
        .reduce((sum, count) => { return sum + count}, 0)
        .value()
      let number = this.salesTable.length
      return {number, total: Number(total)}
    },
    computedDisabledPayment () {
      // 沒有開帳人員不可結帳
      return !this.isCloseOrder || (this.isNew && this.salesTable.length === 0)
    },
    computedFavoProduct () {
      return _.filter(this.products, { favo: true })
    },
    mappingStaff () {
      return _.chain(this.staff)
        .cloneDeep()
        .map(r => {
          r.mappingName = `(${r.staffID}) ${r.name}`
          return r
        })
        .value()
    }
  },
  async mounted () {
    window.scroll(0, 0)
    // pos 頁面才需要支援快捷鍵
    if (this.$router.history.current.fullPath.indexOf('pos') > -1) {
      // F2 / F3 / F8 / F9只能透過keydown
      window.addEventListener('keydown', function(e) {
        if (e.code === 'F3' || e.code === 'F9' || e.code === 'F2' || e.code === 'F8') {
          this.showShortcut(e.code)
        }
      }.bind(this))
      window.addEventListener('keypress', function(e) {
        if (e.code) {
          this.showShortcut(e.code)
        }
      }.bind(this))
    }
    if (this.$router.history.current.params.action === 'modify') {
      this.isNew = false
    }
    this.init()
  }
}
</script>
<style>
  .input-text {
    border: 0 !important;
    box-shadow: none !important;
    border-bottom: 1px solid #d9d9d9 !important;
    border-radius: 0 !important;
    margin: 12px !important;
  }
</style>
