<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(login)">
      <div class="col-md-4 ml-auto mr-auto">
        <card class="card-login card-plain">
          <div slot="header">
            <div class="logo-container">
              <img src="img/zeyutang_logo.png" alt="" />
            </div>
          </div>

          <div>
            <ValidationProvider
              name="name"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <fg-input
                type="text"
                :error="failed ? '請輸入帳號' : null"
                :hasSuccess="passed"
                class="no-border form-control-lg font-22"
                placeholder="請輸入帳號"
                addon-left-icon="now-ui-icons users_single-02"
                v-model="data.name"
              >
              </fg-input>
            </ValidationProvider>

            <ValidationProvider
              name="password"
              rules="required|min:1"
              v-slot="{ passed, failed }"
            >
              <fg-input
                type="password"
                :error="failed ? '請輸入密碼' : null"
                :hasSuccess="passed"
                class="no-border form-control-lg font-22"
                placeholder="請輸入密碼"
                addon-left-icon="now-ui-icons ui-1_lock-circle-open"
                v-model="data.password"
              >
              </fg-input>
            </ValidationProvider>
          </div>
          <div v-if="error.key" class="text-danger">{{error.message}}</div>
          <div slot="footer">
            <n-button native-type="submit" type="primary" round block>
              登入
            </n-button>
          </div>
        </card> 
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import API from 'src/api/index.js'
import { extend } from 'vee-validate'
import { required, min } from 'vee-validate/dist/rules'
import moment from 'moment'

extend('required', required)
extend('min', min)

export default {
  name: 'login',
  data() {
    return {
      data: {
        name: '',
        password: ''
      },
      error: {key: '', message: ''}
    }
  },
  methods: {
    async login() {
      let {name, password} = this.data
      let result = await API.login(JSON.stringify({name, password}))
      let today = moment().format('X')
      if (result.status === 401) {
        this.error = {
          key: 'login',
          message: '帳號或密碼錯誤，請再試一次！'
        }
        return
      }
      if (result.message === 'success' && today <= result.expireAt) {
        let token = result.token.split('.')
        let data = JSON.parse(atob(token[1]))
        let acl = data.data.authority
        localStorage.setItem('chengyoData', JSON.stringify(Object.assign({token: result.token, acl, auth: data.data, expireAt: result.expireAt})))
        this.$router.push('/')
      } else {
        this.error = {
          key: 'login',
          message: '登入失敗，請再試一次！'
        }
      }
    }
  }
}
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
