<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref='form'>
    <form @submit.prevent="handleSubmit(saveProduct)">
      <div class="form-horizontal">
        <div class="row">
          <div class="col-md-12">
            <!-- end start -->
            <card>
              <template slot="header">
                <h4 class="card-title">商品資料</h4>
              </template>
              <div v-for="col in cols" :key="col.id">
                <div v-if="col.show" class="row">
                  <label class="col-sm-2 col-form-label">{{col.text}}</label>
                  <div class="col-sm-10">
                    <!-- select cols -->
                    <div v-if="col.type === 'select'">
                      <el-select class="select-primary" size="large" placeholder="請選擇" v-model="data[col.id]">
                        <el-option v-for="option in selects[col.id]" class="select-primary" :value="option.value" :label="option.label" :key="option.label"></el-option>
                      </el-select>
                      <div class="text-danger m-2" v-if="!vaildate.pass && col.id === vaildate.key">{{vaildate.msg}}</div>
                    </div>
                    <!-- else cols -->
                    <ValidationProvider
                      v-else
                      :name="col.id"
                      :rules="col.rules"
                      v-slot="{ passed, failed }"
                    >
                      <fg-input 
                        :type="col.type"
                        class="form-control-lg"
                        v-model="data[col.id]"
                        :step="col.step"
                        :error="failed ? '*此欄位為必填' : null"
                        :hasSuccess="passed"
                        :placeholder="col.placeholder"
                        :min="col.min"
                        @blur="col.blur"
                        :addon-left-icon="col.icon">
                      </fg-input>
                      <div class="text-danger m-2" v-if="!vaildate.pass && col.id === vaildate.key">{{vaildate.msg}}</div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8 offset-md-2">
                  <blockquote>
                    <div class="blockquote blockquote-primary mt-2">
                      折扣數量說明：
                      <br>
                      當設定折扣數量時，商品數量達到折扣數量，將會以折扣價格計算
                      <br/>
                      如設定折扣數量為7時，折購價格為 $500，表示當該商品數量達到7，
                      <br />
                      則會以折扣價格 $500 計算，其餘與原價計算。
                      <hr>
                      ex: 當條件設定為：折扣數量為7時，折購優惠計價為 $500，標準定價 $100
                      <br />
                      總商品數量為16，金額總價為 $1,400。
                      <br />
                      公式： 18 / 7 = 2 ... 4
                      <br />
                      (2 * 500) + (4 * 100) = $1,400
                    </div>
                  </blockquote>
                </div>
              </div>
            </card>
            <!-- end card -->
          </div>
          <div class="col-md-12">
            <card>
              <template slot="header">
                <h4 class="card-title">更多定價策略</h4>
                <h6 class="card-title">當商品有設定折扣數量，將會以優惠價進行試算</h6>
              </template>
              <div v-for="(col, index) in priceCols" :key="col.id" class="col-8 offset-2">
                <div class="row justify-content-center">
                  <div v-for="subCol in col.subCols" :key="subCol.id" class="col-sm-4">
                    <label class="col-form-label">{{subCol.text}}</label>
                    <ValidationProvider :name="subCol.id" :rules="subCol.rules" v-slot="{ passed, failed }">
                      <fg-input 
                        :type="subCol.type" 
                        :step="subCol.step"
                        class="form-control-lg"
                        v-model="data.priceBook[index][subCol.id]"
                        :error="failed ? '*此欄位為必填' : null"
                        :hasSuccess="passed"
                        :placeholder="subCol.placeholder"
                        :addon-left-icon="subCol.icon">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </card>
          </div>
          <div class="col-md-12">
            <card>
              <template slot="header">
                <h4 class="card-title">更多資訊</h4>
              </template>
              <div>
                <div v-for="col in extraCols" :key="col.id">
                  <div v-if="col.show" class="row">
                    <label class="col-sm-2 col-form-label">{{col.text}}</label>
                    <div class="col-sm-10">
                      <fg-input>
                        <textarea class="form-control" placeholder="在空白處輸入備註內容" v-model="data.description"></textarea>
                      </fg-input>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-end">
                  <button type="submit" class="btn btn-info btn-round" :disabled="!vaildate.pass">{{isNew ? '儲存商品' : '修改商品'}}</button>
                </div>
              </div>
            </card>
          </div>
          <div class="col-md-12">
            <card>
              <template slot="header">
                <h4 class="card-title">更多設定</h4>
              </template>
              <div class="d-flex justify-content-end">
                <!-- <button type="button" class="btn btn-neutral btn-round" @click.prevent="saveProduct('duplicate')">複製產品</button> -->
                <button type="button" v-if="!isNew" class="btn btn-danger btn-round" @click.prevent="deleteProduct">刪除商品</button>
              </div>
            </card>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import API from 'src/api/index.js'
import {Select, Option} from 'element-ui'
import {extend} from 'vee-validate'
import {required} from 'vee-validate/dist/rules'
import Swal from 'sweetalert2'
import moment from 'moment'
import _ from 'lodash'

extend('required', required)

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      data: {
        SKU: '',
        barcode: '',
        name: '',
        description: '',
        category: '',
        taxRate: 0,
        defaultSellingPrice: 0,
        quantityDiscout: 1,
        quantityDiscoutPrice: null,
        createStaff: 'admin',
        createDateTime: null,
        rewardPrice: 0,
        priceBook: [
          {
            id: 'staffPrice',
            name: '員工價',
            price: null,
            discountPrice: null
          },
          {
            id: 'vipPrice',
            name: 'VIP價',
            price:  null,
            discountPrice: null
          },
          {
            id: 'friendPrice',
            name: '親友價',
            price: null,
            discountPrice: null
          }
        ],
        unit: ''
      },
      oldData: {},
      cols: [
        {id: 'SKU', text: '商品編號(SKU)', type:'text', placeholder: '請輸入商品編號(SKU)，SKU 最多16碼英數，有區分大小寫', rules: 'required', show: true, icon: 'now-ui-icons shopping_tag-content', blur: () => {this.validateSku()}},
        {id: 'barcode', text: '條碼編號(barcode)', type:'text', placeholder: '請輸入條碼編號(barcode)', rules: '', show: true, icon: 'now-ui-icons shopping_tag-content'},
        {id: 'name', text: '商品名稱', type:'text', placeholder: '建議12個中文字以內', rules: 'required', show: true, icon: 'now-ui-icons shopping_tag-content'},
        {id: 'category', text: '商品分類', type:'select', placeholder: '請選擇商品分類', rules: '', show: true, icon: 'now-ui-icons shopping_tag-content'},
        {id: 'unit', text: '商品單位', type:'select', placeholder: '請選擇商品單位', rules: 'required', show: true, icon: 'now-ui-icons shopping_tag-content'},
        {id: 'defaultSellingPrice', text: '標準定價', type: 'number', step: 'any', placeholder: '請輸入標準定價', rules: 'required', show: true, min: 0, icon: 'now-ui-icons shopping_tag-content'},
        {id: 'rewardPrice', text: '獎勵金額', type: 'number', step: 'any', placeholder: '請輸入獎勵金額', rules: 'required', show: true, min: 0, icon: 'now-ui-icons shopping_tag-content'},
        {id: 'quantityDiscout', text: '折扣數量', type: 'number', step: 'any', placeholder: '請輸入折扣數量', rules: '', show: true, min: 1, icon: 'now-ui-icons shopping_tag-content'},
        {id: 'quantityDiscoutPrice', text: '折扣優惠計價', type: 'number', step: 'any', placeholder: '請輸入折扣金額', rules: '', show: true, min: 0, icon: 'now-ui-icons shopping_tag-content'},
      ],
      priceCols: [
        {
          id: 'staffPrice',
          text: '員工價',
          subCols: [
            {id: 'price', text: '員工價', type: 'number', step: 'any', placeholder: '請輸入員工價', rules: '',  show: true, icon: 'now-ui-icons shopping_tag-content'},
            {id: 'discountPrice', text: '員工價折扣價格', type: 'number', step: 'any', placeholder: '請輸入員工價折扣價格', rules: '',  show: true, icon: 'now-ui-icons shopping_tag-content'},
            {id: 'rewardPrice', text: '員工價獎勵金額', type: 'number', step: 'any', placeholder: '請輸入員工價獎勵金額', rules: '',  show: true, icon: 'now-ui-icons shopping_tag-content'}
          ]
        },
        {
          id: 'vipPrice',
          text: 'VIP價',
          subCols: [
            {id: 'price', text: 'VIP價', type: 'number', step: 'any', placeholder: '請輸入VIP價', rules: '',  show: true, icon: 'now-ui-icons shopping_tag-content'},
            {id: 'discountPrice', text: 'VIP價折扣價格', type: 'number', step: 'any', placeholder: '請輸入VIP價折扣價格', rules: '',  show: true, icon: 'now-ui-icons shopping_tag-content'},
            {id: 'rewardPrice', text: 'VIP價獎勵金額', type: 'number', step: 'any', placeholder: '請輸入VIP價獎勵金額', rules: '',  show: true, icon: 'now-ui-icons shopping_tag-content'}
          ]
        },
        {
          id: 'friendPrice',
          text: '親友價',
          subCols: [
            {id: 'price', text: '親友價', type: 'number', step: 'any', placeholder: '請輸入親友價', rules: '',  show: true, icon: 'now-ui-icons shopping_tag-content'},
            {id: 'discountPrice', text: '親友價折扣價格', type: 'number', step: 'any', placeholder: '請輸入親友價折扣價格', rules: '',  show: true, icon: 'now-ui-icons shopping_tag-content'},
            {id: 'rewardPrice', text: '親友價獎勵金額', type: 'number', step: 'any', placeholder: '請輸入親友價獎勵金額', rules: '',  show: true, icon: 'now-ui-icons shopping_tag-content'}
          ]
        }
      ],
      extraCols: [
        {id: 'description', text: '備註', type: 'text', placeholder: '請輸入備註內容', rules: '', show: true, icon: 'now-ui-icons ui-1_bell-53'},
      ],
      selects: {
        category: [],
        unit: [
          { value: '顆', label: '顆' },
          { value: '份', label: '份' },
          { value: '克', label: '克' },
          { value: '張', label: '張' },
          { value: '元', label: '元' }
        ]
      },
      vaildate: {pass: true, msg: '', key: null},
      isLoading: false,
      isNew: false
    }
  },
  methods: {
    async init () {
      this.isNew = this.$router.history.current.params.action === 'add'
      let result = await API.getCategory()
      if (result.message === 'success') {
        this.selects.category = result.data.map(r => {
          let { name } = r
          return {value: name, label: name}
        })
      }
      if (!this.isNew) {
        let productUUID = this.$router.history.current.params.id
        if (productUUID) {
          let result = await API.getProduct(productUUID)
          if (result.message === 'success') {
            this.data = result.data[0]
            this.oldData = result.data[0]
            let arr = []
            _.forEach(this.priceCols, r => {
              let obj = _.find(result.data[0].priceBook, {id : r.id})
              if (obj) {
                arr.push(obj)
              }
            })
            this.data.priceBook = arr
          }
        }
      } else {
        this.data.createDateTime = moment().format('X')
      }
    },
    validate() {
      return this.$refs.form.validate().then(res => {
        this.$emit('on-validated', res)
        return res
      })
    },
    async validateSku () {
      if (this.oldData.SKU === this.data.SKU) return
      let result = await API.getProductRepeat(this.data.SKU)
      if (result.repeat) {
        this.vaildate = {pass: false, msg: '產品編號 (SKU) 重複，請再重新輸入', key: 'SKU'}
      } else {
        this.vaildate.pass = true
      }
    },
    async saveProduct (type) {
      this.$refs.form.validate().then(success => {
        if (!success) {
          window.scrollTo({top: 100, behavior: 'smooth'})
        }
      })
      if (this.isNew) {
        // 新增產品
        this.isLoading = true
        let result = await API.createProduct(JSON.stringify(this.data))
        if (result.message === 'success') {
          this.isLoading = false
          Swal.fire({
            title: `更新 ${this.data.name} 產品成功`,
            type: 'success',
          }).then(result => {
            if (result.value) {
              this.$router.push('/product')
            }
          })
        }
      } else {
        // 更新產品 || 複製產品
        if (type === 'duplicate') {
          // 複製產品，或複製產品沒有變更，將不會儲存資料
          return
        } else {
          // 更新產品
          let result = await API.modifyProduct(JSON.stringify(this.data), this.data.productUUID)
          if (result.message === 'success') {
            Swal.fire({
              title: `更新 ${this.data.name} 產品成功`,
              type: 'success',
            }).then(result => {
              if (result.value) {
                this.$router.push('/product')
              }
            })
          }
        }
      }
    },
    async deleteProduct () {
      let productUUID = this.$router.history.current.params.id
      Swal.fire({
        title: `確認刪除 ${this.data.name}`,
        text: '請確認是否刪除，刪除將無法復原產品',
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-danger',
        cancelButtonClass: 'btn btn-default',
        confirmButtonText: '刪除',
        cancelButtonText: '取消',
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          let result = await API.deleteProduct(productUUID)
          if (result.message === 'success') {
            Swal.fire({
              title: '刪除成功!',
              text: `刪除產品 ${this.data.name}`,
              type: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            })
            this.$router.push('/product')
          } else {
            Swal.fire({
              title: '刪除失敗!',
              text: '請再試一次！',
              icon: 'error',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            })
          }
        }
      })
    }
  },
  computed: {
  },
  async mounted () {
    window.scrollTo(0, 0)
    this.init()
  }
}
</script>
<style></style>
