<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(saveData)">
      <div class="form-horizontal">
        <div class="row">
          <div class="col-12">
            <!-- 顧客資料 -->
              <card>
                <template slot="header">
                  <h4 class="card-title">會員資料</h4>
                </template>
                <div class="row">
                  <div class="col-6" v-for="col in cols" :key="col.id">
                    <label class="col-form-label">{{col.text}}</label>
                    <div>
                      <!-- select cols -->
                      <el-select v-if="col.type === 'select'" class="select-primary" size="large" placeholder="請選擇" v-model="data[col.id]">
                        <el-option v-for="option in col.data" class="select-primary" :value="option.value" :label="option.label" :key="option.label"></el-option>
                      </el-select>
                      <!-- else cols -->
                      <ValidationProvider v-else :name="col.id" :rules="col.rules" v-slot="{ passed, failed }">
                        <!-- memberID's disabled -->
                        <fg-input v-if="col.id === 'memberID' && isNew" type="text" class="form-control-lg" v-model="data[col.id]"
                          :error="failed ? '*此欄位為必填' : null"
                          :hasSuccess="passed"
                          :placeholder="col.placeholder"
                          :disabled="false"
                          :addon-left-icon="col.icon">
                        </fg-input>
                        <fg-input v-else type="text" class="form-control-lg" v-model="data[col.id]"
                          :error="failed ? '*此欄位為必填' : null"
                          :hasSuccess="passed"
                          :placeholder="col.placeholder"
                          :disabled="col.disabled"
                          :addon-left-icon="col.icon">
                        </fg-input>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="col-12 d-flex justify-content-end">
                  <button class="btn btn-btn btn-outline-primary btn-round mr-2" @click.prevent="handleShortCutArea">前往諮詢紀錄↓</button>
                  <button type="submit" class="btn btn-btn btn-primary btn-round">{{isNew ? '新增會員資料' : '修改會員資料'}}</button>
                </div>
              </card>
            <!-- 諮詢紀錄 -->
            <card card-body-classes="table-full-width" id="consultationView">
              <div slot="header" class="d-flex justify-content-between">
                <h4 class="card-title">諮詢紀錄</h4>
                <div class="d-flex justify-content-end">
                  <div class="card-title mx-2"><button type="button" class="btn btn-primary btn-round m-0" @click.prevent="saveData('modifyWeight')">體重紀錄</button></div>
                  <div class="card-title"><button type="button" class="btn btn-default btn-round m-0" @click.prevent="exportExcel"><i class="now-ui-icons arrows-1_share-66"></i> 匯出Execl報表</button></div>
                </div>
              </div>
              <el-table :data="data.consultation">
                <el-table-column min-width="100" label="諮詢日期" property="formatDate" sortable></el-table-column>
                <el-table-column min-width="150" label="體重" property="bodyWeight" :formatter="formatter" sortable></el-table-column>
                <el-table-column min-width="150" label="紀錄人" property="staffInfo" sortable></el-table-column>
                <el-table-column min-width="150" label="醫生" property="doctorInfo" sortable></el-table-column>
                <el-table-column min-width="100" label="備註" property="note" sortable></el-table-column>
                <el-table-column min-width="150" label="分店資訊" property="branchID" align="right" header-align="right" sortable></el-table-column>
                <el-table-column
                  align="right"
                  header-align="right"
                  min-width="100"
                >
                  <template slot-scope="{row}">
                    <n-button @click.native="handleModify(row)" class="btn btn-default remove mx-2" type="" size="sm" round icon>
                      <i class="now-ui-icons ui-2_settings-90"></i>
                    </n-button>
                    <n-button @click.native="handleDelete(row)" class="remove" type="danger" size="sm" round icon>
                      <i class="now-ui-icons ui-1_simple-remove"></i>
                    </n-button>
                  </template>
                </el-table-column>
              </el-table>
            </card>
            <!-- 體重歷史紀錄 -->
            <card class="card-chart" no-footer-line>
              <template slot="header">
                <h4 class="card-title"><span class="text-primary">{{data.name}}</span> 的體重變化歷史紀錄</h4>
              </template>
              <div class="chart-area h-100">
                <line-chart :chart-data="datacollection" :height="80"></line-chart>
              </div>
            </card>
            <!-- 修改紀錄 -->
            <card class="card-plain bg-white" card-body-classes="table-full-width">
              <div slot="header" class="d-flex justify-content-between">
                <h4 class="card-title">修改紀錄</h4>
              </div>
              <el-table 
              header-cell-class-name="table-transparent"
              header-row-class-name="table-transparent"
              row-class-name="table-transparent"
              :data="history"
              class="fixedH265">
                <el-table-column min-width="100" label="修改日期" property="time" sortable></el-table-column>
                <el-table-column min-width="80" label="修改人" property="user"></el-table-column>
                <el-table-column min-width="300" label="修改行為" property="actionText" sortable></el-table-column>
              </el-table>
            </card>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import API from 'src/api/index.js'
import { renderStoreType } from 'src/util/index.js'
import {Select, Option, Table, TableColumn } from 'element-ui'
import LineChart from 'src/components/Charts/LineChart'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import Swal from 'sweetalert2'
import {v1 as uuidv1} from 'uuid'
import _ from 'lodash'
import moment from 'moment'
import XLSX from 'xlsx'

extend('required', required)


export default {
  components: {
    LineChart,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      data: {
        uid: '',
        name: '',
        birthday: '',
        memberID: '',
        mobilePhone: '',
        healthCareID: '',
        gender: '',
        note: '',
        bodyWeight: 0,
        type: 'normalPrice',
        consultation: []
      },
      cols: [
        {id: 'memberID', text: '會員編號', placeholder: '請輸入會員編號', rules: 'required', show: true, disabled: true, icon: 'now-ui-icons users_circle-08'},
        {id: 'healthCareID', text: '健保卡編號', placeholder: '請輸入健保卡編號', rules: '', show: true, icon: 'now-ui-icons business_badge'},
        {id: 'name', text: '會員姓名', placeholder: '請輸入會員姓名', rules: 'required', show: true, icon: 'now-ui-icons users_circle-08'},
        {id: 'mobilePhone', text: '會員電話', placeholder: '請輸入會員電話', rules: '', show: true, icon: 'now-ui-icons users_circle-08'},
        {id: 'birthday', text: '會員生日', placeholder: '請輸入會員生日 YYYY/MM/DD', rules: '', show: true, icon: 'now-ui-icons users_circle-08'},
        {id: 'years', text: '年齡', placeholder: '請輸入會員生日', rules: '', show: true, disabled: true, icon: 'now-ui-icons users_circle-08'},
        {id: 'gender', text: '會員性別', placeholder: '請選擇會員性別', rules: '', show: true, type: 'select', data:[{value: 'female', label: '女性'}, {value: 'male', label: '男性'}, {value: 'thirdGender', label: '不指定'}], icon: 'now-ui-icons users_circle-08'},
        {id: 'type', text: '會員類型', placeholder: '請選擇會員類型', rules: '', show: true, type: 'select', data:[{value: 'normalPrice', label: '一般'}, {value: 'staffPrice', label: '員工'}, {value: 'vipPrice', label: 'VIP'}, {value: 'friendPrice', label: '親屬'}], icon: 'now-ui-icons users_circle-08'},
        {id: 'note', text: '備註', placeholder: '請輸入會員備註，建議12個中文字以內', rules: '', show: true, icon: 'now-ui-icons ui-2_chat-round'}
      ],
      staff: '',
      doctor: '',
      isNew: false,
      datacollection: {labels:[], datasets: []},
      history: []
    }
  },
  methods: {
    async fillData () {
      let id = null
      if (this.$router.history.current.params.action === 'modify') {
        id = this.$router.history.current.params.id
      }
      if (id) {
        let result = await API.getMemberConsultation(id)
        if (result.message === 'success') {
          this.data.consultation = _.chain(result.data)
            .cloneDeep()
            .map((r, index, data) => {
              let weightData = data[index === 0 ? index : index - 1].bodyWeight
              let calcWeight = r.bodyWeight - weightData >= 0 ? `+${(r.bodyWeight - weightData).toFixed(1)} kg` : `${(r.bodyWeight - weightData).toFixed(1)} kg`
              let lastBodyWeight = (r.bodyWeight - weightData) === 0 ? '-' : calcWeight
              r.lastBodyWeight = lastBodyWeight
              r.formatDate = moment(r.date, 'X').format('YYYY/MM/DD')
              r.staffInfo = r.staffID ? `${r.staffName} (編號: ${r.staffID})` : '-'
              r.doctorInfo = r.doctorID ? `${r.doctorName} (編號: ${r.doctorID})` : '-'
              r.branchID = renderStoreType(r.branchID) 
              return r
            })
            .reverse()
            .value()
        }
        this.datacollection = {
          labels: _.chain(this.data.consultation)
            .cloneDeep()
            .reverse()
            .map('formatDate')
            .flatten()
            .value(),
          datasets: [
            {
              label: '體重',
              backgroundColor: '#f87979',
              data:_.chain(this.data.consultation)
                .cloneDeep()
                .reverse()
                .map('bodyWeight')
                .flatten()
                .value()
            }
          ]
        }
      }
    },
    async init () {
      let result = await API.getStaff()
      if (result.message === 'success') {
        this.staff = result.data
      }
      if (this.isNew) {
        // 新增
        let availableResult = await API.getMemberAvailable()
        if (availableResult.message === 'success') {
          this.data.memberID = availableResult.data.availableMemberID
        }
      } else {
        let localStorageData = JSON.parse(localStorage.getItem('chengyoData'))
        if (localStorageData && Object.values(_.get(localStorageData, 'member', {})).length > 0) {
          let {uid, healthCareID, id, name, birthday, memberID, note, memberPrefix, mobilePhone, gender, type} = localStorageData.member
          let years = birthday ? moment().years() - moment(birthday).years() : null
          this.data = {uid, healthCareID, id, name, birthday, memberID, note, memberPrefix, mobilePhone, gender, type}
          this.data.years = years ? years + '歲' : '未知歲數'
          this.data.type = type.value ? type.value : 'normalPrice'
          let logResult = await API.getMemberLog(memberID)
          if (logResult.message === 'success') {
            this.history = _.chain(logResult.data)
              .map(r => {
                r.actionText = r.action.type === 'modify' ? '變更會員資料' : ''
                r.time = moment(r.updateTime, 'X').format('YYYY/MM/DD  hh:mm:ss')
                return r
              })
              .reverse()
              .value()
          }
        }
      }
    },
    validate() {
      return this.$refs.form.validate().then(res => {
        this.$emit('on-validated', res)
        return res
      })
    },
    async saveData (type) {
      if (type === 'modifyWeight') {
        let today = moment().format('YYYY-MM-DD')
        let consultation = _.map(this.data.consultation, r => r)
        Swal.fire({
          title: `將紀錄${this.data.name}的體重`,
          html: `<div class="form-horizontal">
                  <div>前次體重記錄為<span class="text-primary">  ${_.get(consultation, '[0]bodyWeight', 0)} </span> ${_.get(consultation, '[0]unit', 'Kg')}</div>
                  <br/>
                  <div class="form-group">
                    <div class="row">
                      <label class="col-md-4 col-form-label">本次體重紀錄</label>
                      <div class="col-md-8">
                        <input id="body-weight" type="number" min="0" placeholder="請輸入體重" class="form-control"/>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <label class="col-md-4 col-form-label">紀錄日期</label>
                      <div class="col-md-8">
                        <input id="date" type="date" value="${today}" class="form-control" />
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <label class="col-md-4 col-form-label">備註</label>
                      <div class="col-md-8">
                        <input id="note" type="text" placeholder="請輸入備註" class="form-control" />
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <label class="col-md-4 col-form-label">紀錄人員</label>
                      <div class="col-md-8">
                        <select id="staff-recordID" class="form-control">
                          ${this.mappingStaff.map(option => `<option value="${option.staffID}">${option.mappingName}</option>`)}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <label class="col-md-4 col-form-label">紀錄醫生</label>
                      <div class="col-md-8">
                        <select id="doctor-recordID" class="form-control ">
                          ${this.mappingDoctor.map(option => `<option value="${option.staffID}">${option.mappingName}</option>`)}
                        </select>                    
                      </div>
                    </div>
                  </div>
                </div>`,
          buttonsStyling: false,
          confirmButtonText: '<i class="fa fa-save"></i> 確認送出',
          confirmButtonClass: 'btn btn-primary btn-lg btn-round btn-block',
          preConfirm: async () => {
            let id = this.$router.history.current.params.id
            let data = {}
            let doctorID = document.getElementById('doctor-recordID').value
            let staffID = document.getElementById('staff-recordID').value
            data = {
              memberID: id,
              bodyWeight: Number(document.getElementById('body-weight').value),
              date: Number(moment().format('X')),
              note: document.getElementById('note').value,
              doctorID: document.getElementById('doctor-recordID').value,
              doctorName: _.filter(this.staff, {staffID: doctorID})[0].name, 
              staffID: document.getElementById('staff-recordID').value,
              staffName:  _.filter(this.staff, {staffID: staffID})[0].name,
              unit: 'kg'
            }
            let result = await API.createMemberConsultation(JSON.stringify(data))
            if (result.message === 'success') {
              this.fillData()
            } else {
              Swal.fire({
                title: `更新 ${this.data.name} 會員體重資料失敗，請洽管理員`,
                icon: 'error'
              })
            }
          }
        })
      } else {
        delete this.data.consultation
        delete this.data.years
        if (this.isNew) {
          let type = {id: uuidv1(), value: this.data.type}
          this.data.type = type.value ? type : {}
          let result = await API.createMember(JSON.stringify(this.data))
          if (result.status === 400) {
            Swal.fire({
              title: '會員編號重複',
              icon: 'error'
            })
          } else if (result.message === 'success') {
            Swal.fire({
              title: `新增 ${this.data.name} 會員資料成功`,
              type: 'success'
            })
            this.$router.push('/member')
          }
        } else {
          let type = {id: uuidv1(), value: this.data.type}
          this.data.type = type.value ? type : {}
          let result = await API.modifyMember(JSON.stringify(this.data), this.data.id)
          if (result.message === 'success') {
            Swal.fire({
              title: `修改 ${this.data.name} 會員資料成功`,
              type: 'success'
            })
            this.$router.push('/member')
          }
        }
      }
    },
    exportExcel () {
      let title = '歷史體重紀錄'
      let sheetList = ['歷史體重紀錄']
      let wb = XLSX.utils.book_new()
      _.forEach(sheetList, name => {
        if (name === '歷史體重紀錄') {
          let ws, resolveData
          let data = _.map(this.data.consultation, r => {
            let { formatDate, bodyWeight, lastBodyWeight, staffInfo, doctorInfo, note, branchID } = r
            return { formatDate, bodyWeight, lastBodyWeight, staffInfo, doctorInfo, note, branchID }
          })
          let header = [{formatDate: '諮詢日期', bodyWeight: '體重', lastBodyWeight: '體重變化',staffInfo: '紀錄人', doctorInfo: '醫生', note: '備註', branchID: '分店資訊'}]
          resolveData = header.concat(data)
          ws = XLSX.utils.json_to_sheet(resolveData, {skipHeader: true, range: 1})
          XLSX.utils.book_append_sheet(wb, ws, name)
        }
      })
      XLSX.writeFile(wb, this.data.name + title + '.xlsx')
    },
    handleShortCutArea () {
      document.getElementById('consultationView').scrollIntoView({ behavior: 'smooth', block: 'center' })
    },
    handleDelete (row) {
      Swal.fire({
        title: `將刪除${this.data.name}此筆的體重`,
        confirmButtonText: '<i class="fa fa-save"></i> 確認刪除',
        confirmButtonClass: 'btn btn-danger btn-lg btn-round',
        cancelButtonText: '取消',
        cancelButtonClass: 'btn btn-lg btn-round',
        showCancelButton: true,
        buttonsStyling: false,
        preConfirm: async () => {
          let result = await API.deleteMemberConsultation(row.id)
          if (result.message === 'success') {
            this.fillData()
          } else {
            Swal.fire({
              title: `刪除 ${this.data.name} 會員體重資料失敗，請洽管理員`,
              icon: 'error'
            })
          }
        }
      })
    },
    handleModify (row) {
      Swal.fire({
        title: `將更新 <span class="text-primary">${this.data.name}</span> 的體重`,
        html: `<br/>
              <div class="form-horizontal">
                <div class="form-group">
                  <div class="row">
                    <label class="col-md-4 col-form-label">本次體重紀錄</label>
                    <div class="col-md-8">
                      <input id="body-weight" type="number" value="${row.bodyWeight}" min="0" placeholder="請輸入體重" class="form-control" />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <label class="col-md-4 col-form-label">紀錄日期</label>
                    <div class="col-md-8">
                      <input id="date" type="date" value="${moment(row.date, 'X').format('YYYY-MM-DD')}" class="form-control" />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <label class="col-md-4 col-form-label">備註</label>
                    <div class="col-md-8">
                      <input id="note" type="text" placeholder="請輸入備註" value="${row.note}" class="form-control" />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <label class="col-md-4 col-form-label">紀錄人員</label>
                    <div class="col-md-8">
                      <select id="staff-recordID" class="form-control">
                        ${this.mappingStaff.map(option => `<option value="${option.staffID}">${option.mappingName}</option>`)}
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <label class="col-md-4 col-form-label">紀錄醫生</label>
                    <div class="col-md-8">
                      <select id="doctor-recordID" class="form-control ">
                        ${this.mappingDoctor.map(option => `<option value="${option.staffID}">${option.mappingName}</option>`)}
                      </select>                    
                    </div>
                  </div>
                </div>
              </div>
              `,
        buttonsStyling: false,
        confirmButtonText: '<i class="fa fa-save"></i> 確認送出',
        confirmButtonClass: 'btn btn-primary btn-lg btn-round btn-block',
        preConfirm: async () => {
          let id = this.$router.history.current.params.id
          let data = {}
          let doctorID = document.getElementById('doctor-recordID').value
          let staffID = document.getElementById('staff-recordID').value
          let date = document.getElementById('date').value
          data = {
            memberID: id,
            bodyWeight: Number(document.getElementById('body-weight').value),
            date: moment(date).format('X'),
            note: document.getElementById('note').value,
            doctorID,
            doctorName: _.filter(this.staff, {staffID: doctorID})[0].name, 
            staffID,
            staffName:  _.filter(this.staff, {staffID: staffID})[0].name,
            unit: 'kg'
          }
          let result = await API.modifyMemberConsultation(JSON.stringify(data), row.id)
          if (result.message === 'success') {
            this.fillData()
          } else {
            Swal.fire({
              title: `更新 ${this.data.name} 會員體重資料失敗，請洽管理員`,
              icon: 'error'
            })
          }
        }
      })
    },
    formatter (row) {
      return `${row.bodyWeight} ${row.unit} (${row.lastBodyWeight})`
    },
  },
  computed: {
    mappingStaff () {
      return _.chain(this.staff)
        .cloneDeep()
        .map(r => {
          r.mappingName = `( ${r.staffID} ) ${r.name}`
          return r
        })
        .filter(r => r.type.shortCode === 'S')
        .value()
    },
    mappingDoctor () {
      return _.chain(this.staff)
        .cloneDeep()
        .map(r => {
          r.mappingName = `( ${r.staffID} ) ${r.name}`
          return r
        })
        .filter(r => r.type.shortCode === 'D')
        .value()
    }
  },
  mounted () {
    window.scroll(0, 0)
    this.isNew = this.$router.history.current.params.action === 'add'
    this.init()
    this.fillData()
  }
}
</script>
<style></style>
