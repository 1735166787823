var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.login)}}},[_c('div',{staticClass:"col-md-4 ml-auto mr-auto"},[_c('card',{staticClass:"card-login card-plain"},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"logo-container"},[_c('img',{attrs:{"src":"img/zeyutang_logo.png","alt":""}})])]),_c('div',[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{staticClass:"no-border form-control-lg font-22",attrs:{"type":"text","error":failed ? '請輸入帳號' : null,"hasSuccess":passed,"placeholder":"請輸入帳號","addon-left-icon":"now-ui-icons users_single-02"},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{staticClass:"no-border form-control-lg font-22",attrs:{"type":"password","error":failed ? '請輸入密碼' : null,"hasSuccess":passed,"placeholder":"請輸入密碼","addon-left-icon":"now-ui-icons ui-1_lock-circle-open"},model:{value:(_vm.data.password),callback:function ($$v) {_vm.$set(_vm.data, "password", $$v)},expression:"data.password"}})]}}],null,true)})],1),(_vm.error.key)?_c('div',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.error.message))]):_vm._e(),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('n-button',{attrs:{"native-type":"submit","type":"primary","round":"","block":""}},[_vm._v(" 登入 ")])],1)])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }