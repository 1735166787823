var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"form-horizontal"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('card',[_c('template',{slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.isNew ? '新增' : '修改')+"登入帳號資料")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('ValidationProvider',{attrs:{"name":"fullName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"label":"使用者全名","type":"text","error":failed ? '請輸入使用者全名' : null,"hasSuccess":passed,"placeholder":"請輸入使用者名稱，EX:王小明"},model:{value:(_vm.data.fullName),callback:function ($$v) {_vm.$set(_vm.data, "fullName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"data.fullName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"label":"登入帳號","type":"text","error":failed ? '請輸入使用者登入帳號' : null,"hasSuccess":passed,"placeholder":"請輸入登入帳號，限使用英數"},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"data.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"label":"Email","type":"email","error":failed ? '請輸入使用者Email' : null,"hasSuccess":passed,"placeholder":"請輸入使用者Email"},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"data.email"}})]}}],null,true)})],1),_c('div',{staticClass:"col-6"},[_c('ValidationProvider',{attrs:{"name":"password","rules":_vm.isNew ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"label":"登入密碼","type":"password","error":failed ? '登入密碼為必填' : null,"hasSuccess":passed,"placeholder":"確認密碼"},model:{value:(_vm.data.password),callback:function ($$v) {_vm.$set(_vm.data, "password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"data.password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password_confirm","rules":_vm.isNew ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"label":"再次確認密碼","type":"password","error":failed ? '登入密碼為必填' : null,"hasSuccess":passed,"placeholder":"再次確認密碼"},model:{value:(_vm.data.password_confirm),callback:function ($$v) {_vm.$set(_vm.data, "password_confirm", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"data.password_confirm"}})]}}],null,true)})],1)]),_c('div',{staticClass:"col-12 d-flex justify-content-end"},[_c('button',{staticClass:"btn btn-btn btn-default btn-round mx-2",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.handleAction('exit')}}},[_vm._v("取消返回")]),_c('button',{staticClass:"btn btn-btn btn-primary btn-round",attrs:{"type":"submit"}},[_vm._v("確認送出")])])])],2)],1)])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }