<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="form-horizontal">
        <div class="row">
          <div class="col-12">
            <card>
              <template slot="header">
                <h4 class="card-title">{{isNew ? '新增' : '修改'}}登入帳號資料</h4>
                <div class="row">
                  <div class="col-6">
                    <ValidationProvider
                      name="fullName"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <fg-input 
                        label="使用者全名"
                        type="text"
                        :error="failed ? '請輸入使用者全名' : null"
                        :hasSuccess="passed"
                        v-model.trim="data.fullName"
                        placeholder="請輸入使用者名稱，EX:王小明">
                      </fg-input>
                    </ValidationProvider>
                    <ValidationProvider
                      name="name"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <fg-input 
                        label="登入帳號"
                        type="text"
                        :error="failed ? '請輸入使用者登入帳號' : null"
                        :hasSuccess="passed"
                        v-model.trim="data.name"
                        placeholder="請輸入登入帳號，限使用英數">
                      </fg-input>
                    </ValidationProvider>
                    <ValidationProvider
                      name="email"
                      rules="email"
                      v-slot="{ passed, failed }"
                    >
                      <fg-input
                        label="Email"
                        type="email"
                        :error="failed ? '請輸入使用者Email' : null"
                        :hasSuccess="passed"
                        placeholder="請輸入使用者Email"
                        v-model.trim="data.email"
                      >
                      </fg-input>
                    </ValidationProvider>
                  </div>
                  <div class="col-6">
                    <ValidationProvider
                      name="password"
                      :rules="isNew ? 'required' : ''"
                      v-slot="{ passed, failed }"
                    >
                      <fg-input 
                        label="登入密碼"
                        type="password"
                        :error="failed ? '登入密碼為必填' : null"
                        :hasSuccess="passed"
                        placeholder="確認密碼" 
                        v-model.trim="data.password">
                      </fg-input>
                    </ValidationProvider>
                    <ValidationProvider
                      name="password_confirm"
                      :rules="isNew ? 'required' : ''"
                      v-slot="{ passed, failed }"
                    >
                      <fg-input 
                        label="再次確認密碼"
                        type="password"
                        :error="failed ? '登入密碼為必填' : null"
                        :hasSuccess="passed"
                        placeholder="再次確認密碼" 
                        v-model.trim="data.password_confirm">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-12 d-flex justify-content-end">
                  <button type="button" class="btn btn-btn btn-default btn-round mx-2" @click.prevent="handleAction('exit')">取消返回</button>
                  <button type="submit" class="btn btn-btn btn-primary btn-round">確認送出</button>
                </div>
              </template>
            </card>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import API from 'src/api/index.js'
import Swal from 'sweetalert2'
import { extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'

extend('required', required)
extend('email', email)

export default {
  components: {
  },
  data() {
    return {
      data: {
        name: '',
        password: '',
        password_confirm: '',
        email: '',
      },
      isNew: false,
      cols: []
    }
  },
  methods: {
    async init () {
      if (!this.isNew) {
        let id = this.$router.history.current.params.id
        let result = await API.getUser(id)
        if (result.message === 'success') {
          let {name, email, fullName} = result.data
          this.data = {
            name,
            email,
            fullName
          }
        }
      }
    },
    async submit () {
      if (this.data.password !== this.data.password_confirm) {
        Swal.fire({
          title: '請再次確認「確認密碼」是否有誤',
          icon: 'error'
        })
        return false
      }
      let result = this.isNew ? await API.createUser(JSON.stringify(this.data)) : await API.modifyUser(JSON.stringify(this.data), this.$router.history.current.params.id)
      if (result.message === 'success') {
        this.$router.push('/accountlist')
      } else {
        Swal.fire({
          title: this.isNew ? '新增' : '修改' + '帳號失敗，請洽管理員',
          icon: 'error'
        })
      }
    },
    handleAction (type) {
      switch (type) {
        case 'exit':
          this.$router.push('/accountlist')
          break
      }
    }
  },
  computed: {
  },
  mounted () {
    window.scroll(0, 0)
    this.isNew = this.$router.history.current.params.action === 'add'
    this.init()
  }
}
</script>
<style></style>
